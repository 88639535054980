<template>
  <div class="bbb-pages">
    <div class="bbb-landing">
      <router-link :to="url_back" class="close-button">
        <img src="/static/img/big-bang-boom/close-bbb-store.svg" width="100" @click="postTracker('close')" />
      </router-link>

      <h2 v-if="isVnSource" class="text-greeting" :class="{ vn: isVnSource }">Chào, {{ name }}</h2>
      <h2 v-else class="text-greeting">Hello, {{ name }}</h2>

      <img src="/static/img/big-bang-boom/title-bbb-store.png" class="title" width="709" height="485" />

      <img
        v-if="isVnSource"
        src="/static/img/big-bang-boom/play-text-bbb-vn.png"
        class="play-text"
        width="363"
        height="105"
      />
      <img v-else src="/static/img/big-bang-boom/play-text-bbb-store.png" class="play-text" width="538" height="52" />

      <router-link :to="url_next" class="play">
        <img src="/static/img/big-bang-boom/play-bbb-store.png" class="play-button" width="341" height="341" />
      </router-link>
    </div>
  </div>
</template>

<script>
import VendingMachineTracker from '@/mixins/vending-machine-tracker';

export default {
  name: 'PimplePopLanding',
  mixins: [VendingMachineTracker],
  computed: {
    name() {
      if (this.$SO.size(this.$store.state.auth.userProfile)) {
        return this.$store.state.auth.userProfile.name;
      } else {
        return '';
      }
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
    url_next() {
      if (this.$route.query.isvn) {
        return `/vending-machine-vn/pimple-pop/how-to-play?isvn=true`;
      } else {
        return `/vending-machine/pimple-pop/how-to-play`;
      }
    },
    url_back() {
      if (this.$route.query.isvn) {
        return `/vending-machine-vn/home?isvn=true`;
      } else {
        const path = Cookies.get('vending_machine_device') == 'true' ? 'home' : 'login';
        return '/vending-machine/' + path;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  position: fixed;
  top: 50px;
  right: 50px;
}
.bbb-landing {
  min-height: 100vh;
  padding: 134px 24px 0;
  text-align: center;
  background: #000 url('/static/img/big-bang-boom/bg-bbb-2-store.png') no-repeat center;
  background-size: cover;
}
.text-greeting {
  font: normal 70px/98px 'brandontext-bold', sans-serif;
  margin: 0 0 188px;
  color: #fff;
}
.vn {
  font-family: 'SVN-brandontext-regular', sans-serif;
}
.title {
  margin: 0 auto 138px;
  display: block;
}
.play-text {
  margin: 0 auto 30px;
  display: block;
}
</style>
